
















































































































































































import { Component, Vue } from "vue-property-decorator";
import {
    Core
} from '@/store/core'
import {
    Auth
} from '@/store/auth'
import {
    User
} from '@/store/user'
import {
    Web
} from '@/store/web'
import firebase from 'firebase'
const sign_in_btn = document.querySelector("#sign-in-btn");
const sign_up_btn = document.querySelector("#sign-up-btn");
const containerR = document.querySelector(".containerR");
@Component({
    components: {},
    computed: {},
})
export default class Test extends Vue {
    tab: number = 0;
    switchc: string = "";
    page: any = ''
    private form: any = {}
    regitserDialog: boolean = false;
    formReg: any = {}
    errorReg: any = null
    errorLogin: any = null

    async closeRegDialog() {
        this.formReg = {};
        this.errorReg = null
        this.regitserDialog = false

    }
    async userRegister() {
        this.regitserDialog = false;
       await Web.switchLoad(true)
        let reg = await Core.postHttp(`/accounts/register/`, this.formReg)
        if (reg.id) {
            this.regitserDialog = false
            this.form.username = this.formReg.username
            this.form.password = this.formReg.password
            await this.getLogin();
        } else {
            alert('ไม่สามารถสมัครสมาชิกได้ กรุณาตรวจสอบข้อมูลการสมัครให้ถูกต้อง')
            this.errorReg = reg
            this.regitserDialog = true;
        }
        await Web.switchLoad(false)

    }

    async created() {
        await Web.switchLoad(true)
        try {
            await Auth.run();
            await this.callback()
        } catch (Error) {
            //alert('error')
            await Web.offLoad()
        }

        await Web.offLoad()
    }
    async ChangeTo(val: string) {
        this.switchc = val;
        await this.sleep(1000);
        this.tab = val ? 1 : 0;
    }
    async sleep(ms: any) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    async microsoft() {
        await Auth.reToken();
        let user:any = await Auth.loginMicrosoft365();
        var iOS = ['iPad', 'iPhone', 'iPod', 'MacIntel'].indexOf(navigator.platform) >= 0;
        if(iOS){
            await Web.switchLoad(true)
            console.log(user)
            await this.callbackPopUp(user)
            await Web.switchLoad(false)
        }
    }
    async facebook() {
        await Auth.reToken();
        let user:any = await Auth.loginFacebook();
        var iOS = ['iPad', 'iPhone', 'iPod', 'MacIntel'].indexOf(navigator.platform) >= 0;
        if(iOS){
            await Web.switchLoad(true)
            console.log(user)
            await this.callbackPopUp(user)
            await Web.switchLoad(false)
        }
    }
    async google() {
        await Auth.reToken();
        let user:any = await Auth.loginGoogle();
        var iOS = ['iPad', 'iPhone', 'iPod', 'MacIntel'].indexOf(navigator.platform) >= 0;
        if(iOS){
            await Web.switchLoad(true)
            console.log(user)
            await this.callbackPopUp(user)
            await Web.switchLoad(false)
        }
    }
    async callbackPopUp(user:any) {
        this.page = this.$route.query.web

        let logined: any = user
        let form: any | null = null
        if (logined.type) {
            console.log(logined.user)
            await Auth.reToken();
            form = await Auth.genForm(logined.type, logined.user)
            if ((await Auth.checkUser(form.username)).user) {
                let key = await Core.postHttp(`/rest-auth/login/`, form)
                await this.keyCall(key.key)
            } else {
                let user = await Core.postHttp(`/api/ita/v1/register/`, form)
                if (user.id) {
                    let key = await Core.postHttp(`/rest-auth/login/`, form)
                    await this.keyCall(key.key)
                }
            }
        }
    }

    async callback() {
        this.page = this.$route.query.web

        let logined: any = await Auth.callback();
        let form: any | null = null
        if (logined.type) {
            console.log(logined.user)
            await Auth.reToken();
            form = await Auth.genForm(logined.type, logined.user)
            if ((await Auth.checkUser(form.username)).user) {
                let key = await Core.postHttp(`/api/ita/v2/accounts/`, form) 
                await this.keyCall(key.key)
            } else {
                let user = await Core.postHttp(`/api/ita/v1/register/`, form)
                if (user.id) {
                    let key = await Core.postHttp(`/api/ita/v2/accounts/`, form)
                    await this.keyCall(key.key)
                }
            }
        }
    }

    async getLogin() {
        await Web.switchLoad(true)
        await Auth.reToken();
        this.errorLogin = null
        let key = await Core.postHttp(`/rest-auth/login/`, this.form)
        if (key.key) {
            await this.keyCall(key.key)
            await localStorage.setItem('page','eit')
           
        }else{
             await Web.switchLoad(false)
          this.errorLogin = "กรุณาตรวจสอบข้อมูล ชื่อผู้ใช้ หรือ รหัสผ่าน ให้ถูกต้อง"
          alert('ไม่สามารถเข้าสู่ระบบได้กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
        }
    }

    async keyCall(Key: string) {
        await Auth.storeToken(Key)
        await Auth.storeTokenToStorage(Key)
        await localStorage.setItem('page','eit') 
        await User.loadUser()
        await window.location.replace("/eit/year/?auto=1");

    }
}
